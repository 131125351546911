import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import BeatLoader from 'react-spinners/BeatLoader'
import { Controller, useForm } from 'react-hook-form'

import BDayMobileImage from '../images/bg-bday-mobile.jpg'
import BDayYears from '../images/bday-years.inline.svg'
import LogoTuya from '../images/logo-white.inline.svg'

import encode from '../helpers/encode'
import '../styles/pages/event-subscription.scss'
import SEO from '../components/seo'

const EventSubscriptionPage = ({ location }) => {
  const [state, setState] = React.useState({})
  const [isSuccess, setIsSuccess] = React.useState(null)
  const { register, handleSubmit, control, errors } = useForm()

  useEffect(() => {
    return () => {
      setIsSuccess(null)
    }
  }, [])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    setIsLoading(true)
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setIsSuccess(true)
        // navigate(form.getAttribute('action'), {
        //   state: { isSuccess: true },
        // })
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <SEO title="Private Event" />
      <div className="relative h-screen overflow-hidden bday">
        <img
          className="object-cover object-center w-full"
          alt="Confirmation"
          src={BDayMobileImage}
        />

        <div className="absolute left-0 flex flex-col top-0 w-full h-full">
          <BDayYears className="justify-self-center mx-auto mt-10 max-w-[160px]" />

          <div className="flex justify-items-center w-[262px] mx-auto mt-6">
            {isSuccess === true ? (
              <div className="text-[#ffffff] text-xl md:text-2xl text-center w-full">
                Thank you for
                <br /> registering,
                <br /> see you there!
              </div>
            ) : (
              <form
                name="Private Event Subscription"
                method="post"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit(onSubmit)}
                className="w-full"
              >
                <input type="hidden" name="form-name" value="Private Event Subscription" />
                <input type="hidden" name="title" value="Private Event Subscription" />
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
                <p className="text-[#0092FF] text-xl md:text-2xl text-center">
                  Please confirm
                  <br />
                  your participation
                </p>
                <div className="mt-8">
                  <TextField
                    label="Your name"
                    name="name"
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={errors && errors.name && errors.name.message}
                    inputRef={register({
                      required: 'This field is required',
                      pattern: {
                        value: /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/,
                        message: 'Please enter valid name',
                      },
                    })}
                  />
                </div>
                <div className="mt-6">
                  <TextField
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    error={Boolean(errors.email)}
                    helperText={errors && errors.email && errors.email.message}
                    inputRef={register({
                      required: 'This field is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Please enter valid email address',
                      },
                    })}
                  />
                </div>
                <div className="mt-6">
                  <TextField
                    label="Number of persons"
                    name="persons"
                    onChange={handleChange}
                    error={Boolean(errors.persons)}
                    helperText={errors && errors.persons && errors.persons.message}
                    inputRef={register({
                      required: 'This field is required',
                    })}
                  />
                </div>

                <div className="text-center">
                  <button
                    disabled={isLoading}
                    style={{
                      marginTop: '60px',
                      fontSize: 13,
                      letterSpacing: '1.86px',
                      width: 200,
                      height: 50,
                      color: 'white',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      border: 'solid 1px #ffffff',
                    }}
                  >
                    {!isLoading ? 'SUBMIT' : <BeatLoader color="white" loading={true} size={16} />}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        <LogoTuya className="absolute bottom-[30px] left-0 right-0 m-auto hidden sm:block" />
      </div>
    </>
  )
}

export default EventSubscriptionPage
